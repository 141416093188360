import {Component, HostListener, Injector, OnInit, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Router, RouterModule} from '@angular/router';
import {FormsModule, NgForm} from '@angular/forms';
import {MatButton} from '@angular/material/button';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatError, MatFormField, MatHint, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatOption} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import {NgxCaptchaModule, ReCaptcha2Component} from '@node_modules/ngx-captcha';
import {NgxMatIntlTelInputComponent} from '@node_modules/ngx-mat-intl-tel-input-v16';
import {PublicLanguagesComponent} from './public-languages/public-languages.component';
import {SharedModule} from '@shared/shared.module';
import {AppComponentBase} from '@shared/app-component-base';
import {ClientDto, CustomerInquiryDto, CustomerInquirySServiceProxy} from '@shared/service-proxies/service-proxies';
import {NotifyService} from '@node_modules/abp-ng2-module';
import {gsap} from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';

gsap.registerPlugin(TextPlugin);
@Component({
  selector: 'app-public',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatButton,
    MatCheckbox,
    MatError,
    MatHint,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    NgxCaptchaModule,
    NgxMatIntlTelInputComponent,
    PublicLanguagesComponent,
    SharedModule,
    NgxCaptchaModule,
    NgxMatIntlTelInputComponent,
    MatFormField
  ],
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.css']
})
export class PublicComponent extends AppComponentBase implements OnInit {
  @ViewChild('contactUsForm') contactUsForm: NgForm;
  @ViewChild(ReCaptcha2Component) recaptchaComponent: ReCaptcha2Component;
  date: Date = new Date();
  newMessage: CustomerInquiryDto = new CustomerInquiryDto();
  siteKey = '6LeCQAEqAAAAAGyFr2F6DgFiRIvhYnbRgda63sUD'; // Замените на ваш site key
  isDesktop: boolean = window.innerWidth > 1011;
  showMobileMenu = false;
  activeSection = 'home';

  scale = 1; // Начальный масштаб текста
  translateX = 0; // Начальное смещение влево
  opacity = 1; // Начальная непрозрачность

  wheelX = 500; // Начальное смещение колеса вправо
  wheelOpacity = 0; // Начальная прозрачность колеса
  wheelRotation = 0;

  upForDriveLink = 'https://www.ufd.optimisare.com';
  upForDriveRegistrationLink = 'https://www.ufd.optimisare.com';

  tl1 = gsap.timeline({defaults: {duration: 2, ease: 'none'}});

  constructor(
    injector: Injector,
    private customerInquirySServiceProxy: CustomerInquirySServiceProxy,
    private notifyService: NotifyService,
    private router: Router,
  ) {
    super(injector);
    this.newMessage.client = new ClientDto();
    window.addEventListener('resize', () => {
      this.isDesktop = window.innerWidth > 1011;
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const homeSection = document.getElementById('home');
    if (!homeSection) {
      return;
    }

    const scrollPosition = window.scrollY; // Текущая прокрутка
    const maxScroll = 700; // Максимальная прокрутка для полного уменьшения
    const minScale = 0.5; // Минимальный масштаб текста

    // Нормализуем прогресс прокрутки
    const progress = Math.min(scrollPosition / maxScroll, 1); // От 0 до 1

    // Линейно уменьшаем масштаб текста
    this.scale = 1 - progress * (1 - minScale); // От 1 до minScale

    // Вычисляем максимально возможное смещение текста влево (от центра экрана до левого края)
    const windowWidth = window.innerWidth;
    const maxTranslateX = windowWidth * 0.6; // 0.8 ширины окна

    // Линейное смещение текста влево
    this.translateX = progress * maxTranslateX; // От 0 до maxTranslateX

    const rect = homeSection.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    // Рассчитываем процент видимости секции home
    const visibility = Math.max(0, Math.min(1, rect.bottom / windowHeight));

    // Логика появления и исчезновения
    if (visibility > 0.1) {
      this.opacity = 1;
    } else {
      this.opacity = 0;
    }

    // Управление колесом
    const maxTranslateWheel = windowWidth * 0.8; // Максимальное смещение (начальная позиция у правого края экрана)
    const targetPosition = windowWidth * 0.1; // Конечная позиция
    const wheelProgress = Math.min(scrollPosition / maxScroll, 1.5) + 0.6; // Прогресс от 0 до 1
    // console.log('wheelProgress: ', wheelProgress);
    // Смещение колеса
    this.wheelX = maxTranslateWheel * (1 - wheelProgress) - (1 - wheelProgress) * targetPosition;

    // Радиус колеса
    const wheelRadius = 175; // Половина ширины колеса (350px / 2)

    // Угол поворота колеса
    this.wheelRotation = (this.wheelX / (2 * Math.PI * wheelRadius)) * 360; // Угол в градусах

    // Прозрачность колеса
    if (visibility > 0.1) {
      this.wheelOpacity = 1;
    } else {
      this.wheelOpacity = 0;
    }
  }

  ngOnInit(): void {
    this.onWindowScroll(); // Проверить положение при загрузке
    const sections = document.querySelectorAll('div[id]');
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.activeSection = entry.target.id;
            if (this.activeSection === 'about-us') {
              this.animate1();
            }
          }
        });
      },
      {threshold: 0.5} // Активируется, если более 50% секции видимо
    );

    sections.forEach((section) => observer.observe(section));
  }

  animate1() {
    this.tl1.to('.animate-about-us', {
      text: {
        value: this.l('AboutUs1'),
      },
      duration: 8,
      ease: 'none',
    });
  }

  toggleMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu;
  }

  isActive(sectionId: string): boolean {
    return this.activeSection === sectionId;
  }

  scrollToSection(sectionId: string): void {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
    if (!this.isDesktop) {
      this.toggleMobileMenu(); // Для мобильного меню вызываем метод toggleMobileMenu()
    }
  }

  handleCaptchaResponse(response: string): void {
    this.newMessage.recaptchaToken = response;
  }

  submit() {
    if (this.contactUsForm.invalid || !this.newMessage.recaptchaToken) {
      this.notifyService.error(this.l('AllMandatoryFieldsMustBeCompleted'));
      return;
    }
    // console.log('newMessage: ', this.newMessage);
    this.customerInquirySServiceProxy.create(this.newMessage).subscribe(
      (response) => {
        console.log('POST request successful', response);
        this.notifyService.success(this.l('YourMessageHasBeenSuccessfullySent'));
        this.scrollToSection('home');
      },
      (error) => {
        this.notifyService.error('Error: ', error.error);
        console.error('POST request error', error);
      }
    );
  }

}
