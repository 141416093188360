<!--TOP-->
<div class="main-content">
  <div class="content row">
    <div class="logo-top-div">
      <a href="https://www.optimisare.com/">
        <img class="logo-top" src="/assets/img/logo.svg" alt="Optimisare Software for business workflow optimisation"/>
      </a>
    </div>
    <div class="header-content">
      <div class="top-menu-items row" *ngIf="isDesktop; else mobileMenu">
        <a class="top-menu-item" (click)="scrollToSection('home')">
          <div [ngClass]="{ 'active': isActive('home') }">{{ "Home" | localize }}</div>
        </a>
        <a class="top-menu-item" (click)="scrollToSection('about-us')">
          <div [ngClass]="{ 'active': isActive('about-us') }">{{ "AboutUs" | localize }}</div>
        </a>
        <a class="top-menu-item" style="width: 120px;" (click)="scrollToSection('solutions')">
          <div [ngClass]="{ 'active': isActive('solutions') }">{{ "Solutions" | localize }}</div>
        </a>
        <a class="top-menu-item" style="width: 100px;" (click)="scrollToSection('contact-us')">
          <div [ngClass]="{ 'active': isActive('contact-us') }">{{ "ContactUs" | localize }}</div>
        </a>
      </div>
      <ng-template #mobileMenu>
        <i class="fas fa-bars" (click)="toggleMobileMenu()"></i>
        <div class="mobile-menu" *ngIf="showMobileMenu">
          <div class="top-menu-item" [ngClass]="{ 'active': isActive('home') }">
            <a (click)="scrollToSection('home')">{{ "Home" | localize }}</a>
          </div>
          <div class="top-menu-item" [ngClass]="{ 'active': isActive('about-us') }">
            <a (click)="scrollToSection('about-us')">{{ "AboutUs" | localize }}</a>
          </div>
          <div class="top-menu-item" [ngClass]="{ 'active': isActive('solutions') }">
            <a (click)="scrollToSection('solutions')">{{ "Solutions" | localize }}</a>
          </div>
          <div class="top-menu-item" [ngClass]="{ 'active': isActive('contact-us') }">
            <a (click)="scrollToSection('contact-us')">{{ "ContactUs" | localize }}</a>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="languages-container">
      <app-public-languages></app-public-languages>
    </div>
  </div>
</div>

<!-- HOME -->
<div id="home" class="main-content1">
  <div
      [style.transform]="'translate(-50%, -50%) scale(' + scale + ') translateX(-' + translateX + 'px)'"
      [style.opacity]="opacity">
    {{ "Home1" | localize }}
  </div>
  <img
      src="/assets/img/wheel.png"
      alt="wheel"
      class="animated-wheel"
      [style.transform]="'translate(' + wheelX + 'px, -50%) rotate(' + wheelRotation + 'deg)'"
      [style.opacity]="wheelOpacity"
  />
</div>

<!--ABOUT US-->
<div id="about-us" class="main-content2">
  <div class="content-space2">
    <h2 class="content-header2">{{ "AboutUs" | localize }}</h2>
    <div class="content-b2 animate-about-us"></div>

    <!--    <div class="content-b2">-->
    <!--      {{ "AboutUs1" | localize }}-->
    <!--    </div>-->
    <div class="content2">
      {{ "AboutUs2" | localize }}
    </div>
    <div class="content2">
      <div class="block-container">
        <div class="info-block">{{ "AboutUs3" | localize }}</div>
        <div class="info-block">{{ "AboutUs4" | localize }}</div>
        <div class="info-block">{{ "AboutUs5" | localize }}</div>
        <div class="info-block">{{ "AboutUs6" | localize }}</div>
        <div class="info-block">{{ "AboutUs7" | localize }}</div>
      </div>
    </div>
    <div class="content2">
      {{ "AboutUs8" | localize }}
    </div>
    <div class="content2">
      {{ "AboutUs9" | localize }}
      <a class="top-menu-item" style="color: #FCAF3B;" (click)="scrollToSection('contact-us')">
        {{ "ContactUs" | localize }}
      </a>{{ "AboutUs10" | localize }}
    </div>
  </div>
</div>

<!--Our solutions-->
<div id="solutions" class="main-content3">
  <div class="content-space3">
    <h2 class="content-header3">{{ "OurSolutions" | localize }}</h2>
    <div class="content3">
      {{ "OurSolutions1" | localize }}
    </div>
    <div class="content3-b">
      {{ "OurSolutions2" | localize }}
      UpForDrive
    </div>
    <div class="content3">
      UpForDrive
      {{ "OurSolutions3" | localize }}
      UpForDrive
      {{ "OurSolutions4" | localize }}
    </div>
    <div class="content3">
      {{ "OurSolutions5" | localize }}
    </div>
    <div class="content3">
      <ul>
        <li><strong>{{ "OurSolutions6" | localize }}</strong> {{ "OurSolutions7" | localize }}</li>
        <li><strong>{{ "OurSolutions8" | localize }}</strong> {{ "OurSolutions9" | localize }}</li>
        <li><strong>{{ "OurSolutions10" | localize }}</strong> {{ "OurSolutions11" | localize }}</li>
        <li><strong>{{ "OurSolutions12" | localize }}</strong> {{ "OurSolutions13" | localize }}</li>
      </ul>
    </div>
    <div class="content3">
      {{ "OurSolutions14" | localize }}
    </div>
    <div class="content3">
      <ul>
        <li><strong>{{ "OurSolutions15" | localize }}</strong> {{ "OurSolutions16" | localize }}</li>
        <li><strong>{{ "OurSolutions17" | localize }}</strong> {{ "OurSolutions18" | localize }}</li>
        <li><strong>{{ "OurSolutions19" | localize }}</strong> {{ "OurSolutions20" | localize }}</li>
      </ul>
    </div>
    <div class="content3">
      UpForDrive
      {{ "OurSolutions21" | localize }}
      <a href="{{upForDriveRegistrationLink}}" target="_blank" style="color: #FCAF3B; text-decoration: underline; font-weight: bold;">
        {{ "OurSolutions22" | localize }}
      </a>
      {{ "OurSolutions23" | localize }}
    </div>
    <div class="content3-b">
      {{ "OurSolutions24" | localize }}
    </div>
    <div class="content3">
      {{ 'OurSolutions25' | localize }}
      <a class="top-menu-item" style="color: #FCAF3B;" (click)="scrollToSection('contact-us')">{{ "ContactUs" | localize }}</a>
      {{ "OurSolutions26" | localize }}
    </div>
  </div>
</div>

<!--CONTACT US-->
<div id="contact-us" class="main-content4">
  <div class="content-space4">
    <form #contactUsForm="ngForm">

      <div class="content-header4">{{ "ContactUs1" | localize }}</div>
      <div class="row">

        <!-- Title -->
        <mat-form-field class="col-xl-1 col-lg-2 col-sm-2">
          <mat-label>{{ "Title" | localize }}</mat-label>
          <mat-select name="title" [(ngModel)]="newMessage.client.title" required>
            <mat-option [value]="'Mr' | localize">{{ "Mr" | localize }}</mat-option>
            <mat-option [value]="'Mrs' | localize">{{ "Mrs" | localize }}</mat-option>
            <mat-option [value]="'Miss' | localize">{{ "Miss" | localize }}</mat-option>
            <mat-option [value]="'Ms' | localize">{{ "Ms" | localize }}</mat-option>
            <mat-option [value]="'Other' | localize">{{ "Other" | localize }}</mat-option>
            <mat-option [value]="'PreferNotToSay' | localize">{{ "PreferNotToSay" | localize }}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Name -->
        <mat-form-field class="col-xl-5 col-lg-4 col-sm-10">
          <mat-label>{{ "FirstName" | localize }}</mat-label>
          <input matInput name="name" [(ngModel)]="newMessage.client.name" required maxlength="30">
        </mat-form-field>

        <!-- Surname -->
        <mat-form-field class="col-xl-6 col-lg-6 col-sm-12">
          <mat-label>{{ "Surname" | localize }}</mat-label>
          <input matInput name="surname" [(ngModel)]="newMessage.client.surname" required maxlength="30">
        </mat-form-field>

        <!-- Email -->
        <mat-form-field class="col-xl-6 col-lg-6 col-sm-12">
          <mat-label>{{ "EmailAddress" | localize }}</mat-label>
          <input matInput
                 name="email"
                 [(ngModel)]="newMessage.client.email"
                 required
                 maxlength="30"
                 type="email"
                 pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,})+$"
          >
        </mat-form-field>

        <!-- Phone -->
        <mat-form-field class="col-xl-6 col-lg-6 col-sm-12">
          <ngx-mat-intl-tel-input
              [preferredCountries]="['gb']"
              [enablePlaceholder]="true"
              [enableSearch]="true"
              [(ngModel)]="newMessage.client.phone"
              name="phone"
              #phone
          ></ngx-mat-intl-tel-input>
          <mat-hint>e.g. {{ phone.selectedCountry.placeHolder }}</mat-hint>
          <mat-error *ngIf="contactUsForm.form.controls['phone']?.errors?.required">
            {{ "RequiredField" | localize }}
          </mat-error>
          <mat-error *ngIf="contactUsForm.form.controls['phone']?.errors?.validatePhoneNumber">
            {{ "InvalidNumber" | localize }}
          </mat-error>
        </mat-form-field>

        <!-- Subject -->
        <mat-form-field class="col-xl-6 col-lg-6 col-sm-12">
          <mat-label>{{ "Organisation" | localize }}</mat-label>
          <input matInput name="subject" [(ngModel)]="newMessage.client.organisation" maxlength="50">
        </mat-form-field>

        <!-- Message -->
        <mat-form-field>
          <mat-label>{{ "Message" | localize }}</mat-label>
          <textarea matInput name="message" [rows]="5" [(ngModel)]="newMessage.message" required maxlength="300"></textarea>
        </mat-form-field>

        <!-- isEmailSubscriptionConfirmed-->
        <mat-checkbox ngModel [(ngModel)]="newMessage.client.isEmailSubscriptionConfirmed"
                      name="isEmailSubscriptionConfirmed">
          {{ 'OptInToReceiveCommunicationsFromOptimisareToYourInbox' | localize }}
        </mat-checkbox>

      </div>

      <!-- Интеграция ngx-captcha -->
      <ngx-recaptcha2 [siteKey]="siteKey" (success)="handleCaptchaResponse($event)"></ngx-recaptcha2>

      <button mat-raised-button type="submit" class="btn btn-toolbar pull-right" style="margin-left: 20px;" (click)="submit()">
        {{ "Submit" | localize }}
      </button>
    </form>
  </div>
</div>

<!--FOOTER-->
<div class="card-footer footer-bg-color">
  <div class="content5">
    <div class="footer-left">
      {{ "Bottom1" | localize }}
      <br>
      {{ "Bottom2" | localize }}
    </div>
    <div class="footer-right">
      Copyright © {{ date.getFullYear() }} Optimisare Ltd.
      <br>
      {{ "Bottom3" | localize }}
    </div>
  </div>
</div>
