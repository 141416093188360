import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PublicComponent} from './public/public.component';

const routes: Routes = [
  {
    path: '',
    component: PublicComponent,
    // children: [
    //   { path: '', redirectTo: 'home', pathMatch: 'full' },
    //   { path: 'home', component: HomeComponent },
    //   { path: 'about-us', component: AboutUsComponent },
    //   { path: 'contact-us', component: ContactUsComponent },
    //   { path: 'products', component: ProductsComponent }
    // ]
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
    data: {preload: true}
  },
  {
    path: 'app',
    loadChildren: () => import('./app/app.module').then(m => m.AppModule),
    data: {preload: true}
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false})],
  exports: [RouterModule]
})
export class RootRoutingModule {
}
